import { EventBus } from 'src/core/event/EventBus'
import usuarioLoginStore from 'src/store/usuario-login'
import Header from 'src/components/header.vue'
// import ModalCadastro from 'src/components/ModalCadastro/ModalCadastro.vue'
import ElasticSearchModel from 'src/model/elasticsearch/ElasticSearchModel'
// import CarregandoGeral from 'src/components/CarregandoGeral/Carregando.vue'
import { LocalStorage, debounce } from 'quasar'

export default {
  name: 'MainLayout',
  components: {
    Header,
    Footer: () => import('src/components/footer.vue'),
    ModalCadastro: () =>
      import('src/components/ModalCadastro/ModalCadastro.vue')
    // CarregandoGeral
  },
  data () {
    return {
      mostrarCarregamento: true,
      value: false,
      // containersugestoes: true,
      tipoModal: 2,
      busca_geral: '',
      slideBusca: false,
      forcerRender: 1
    }
  },
  beforeCreate () {
    if (!this.$store.hasModule('usuario-login')) { this.$store.registerModule('usuario-login', usuarioLoginStore) }
  },
  mounted () {
    // if (this.$route.name === 'veiculo') {
    //   this.definirAlturaBotoes(true)
    // }
    // document.querySelector('.b24-widget-button-social-item').setAttribute('href', '#')
    let selection = document.querySelector('.b24-widget-button-social-item')
    if (selection !== null) {
      document
        .querySelector('.b24-widget-button-social-item')
        .setAttribute('href', '#')
    }
    // console.log(document.querySelector('.b24-widget-button-social-item'))
    this.$store.dispatch('usuario-login/renovarUsuario')
    this.atualizarQtdPaginas()
    this.buscarPossiveis()
    this.buscar()
    this.requestNotificationPermission()
    // setTimeout(() => {
    //   this.mostrarCarregamento = false
    // }, 300)
  },
  computed: {
    logado: {
      get () {
        return this.$store.getters['usuario-login/get']
      }
    },
    mostrarBotoes () {
      return true
    }
  },
  watch: {
    busca_geral: {
      handler: debounce(function () {
        console.log('passou')
        this.atualizarBusca()
      }, 3000),
      deep: true
    }
    // ,
    // '$route.name' (agora) {
    //   if (this.mostrarCarregamento) {
    //     setTimeout(() => {
    //       this.mostrarCarregamento = false
    //     }, 1300)
    //   }
    // }
  },
  methods: {
    // definirAlturaBotoes (veiculo) {
    //   const btnBottom = document.querySelector('.b24-widget-button-position-bottom-right')
    //   const btnWhats = document.querySelector('.botoes')
    //   if (btnBottom && btnWhats) {
    //     btnBottom.style.bottom = veiculo ? '100px' : '55px'
    //     btnWhats.style.bottom = veiculo ? '100px' : '57px'
    //   }
    // },
    requestNotificationPermission () {
      if (process.env.MODE !== 'ssr' || process.env.CLIENT) {
        if ('Notification' in window) {
          Notification.requestPermission().then(result => {
            console.log('User choice', result)
            if (result !== 'granted') {
              console.log('No notification permission granted!')
            } else {
              // var not = new Notification('Título da Notificação', {
              //   body: 'Esta é a mensagem da notificação.'
              // })
              // not.onclick = function () {
              //   console.log('Notification clicked')
              // }
              console.log('Notification permission granted!')
              // registration.pushManager.subscribe({
              //   userVisibleOnly: true,
              //   applicationServerKey: 'SUA_CHAVE_PUBLICA_VAPID'
              // }).then(function (subscription) {
              //   // Envie a `subscription` para o backend via AJAX ou API
              // })
            }
          })
        }
      }
    },
    atualizarBusca () {
      if (process.env.MODE !== 'ssr' || process.env.CLIENT) {
        if (window.location.href.indexOf('comprar') > -1) {
          EventBus.$emit('busca_geral', this.busca_geral)
        } else {
          // this.$router.push('comprar',param)
          this.$router.push({
            name: 'comprar',
            query: { busca_geral: this.busca_geral }
          })
          // this.containersugestoes = false
          // window.location.href = '/comprar?busca_geral=' + this.busca_geral
        }
      }
    },
    atualizarQtdPaginas () {
      this.widthCard = 375
      this.heightCard = 380
      this.widthBarraBusca = 320
      this.heightTopo = 80
      if (this.$q.screen.width !== this.widthAtualizado) {
        this.widthAtualizado = this.$q.screen.width
        this.qtdPorLinha = Math.floor(
          (this.widthAtualizado - this.widthBarraBusca) / this.widthCard
        )
        // console.warn(this.widthCard, 'widthCard')
        // console.warn(this.widthAtualizado, 'widthAtualizado')
        // console.warn(this.qtdPorLinha, 'qtdPorLinha')
      }
      if (this.$q.screen.height !== this.heightAtualizado) {
        this.heightAtualizado = this.$q.screen.height
        this.qtdLinhas = Math.floor(
          (this.heightAtualizado - this.heightTopo) / this.heightCard
        )
        // console.warn(this.heightAtualizado, 'heightAtualizado')
        // console.warn(this.qtdLinhas, 'qtdLinhas')
      }
      if (this.qtdLinhas <= 2) {
        this.qtdLinhas = 3
      }
      this.qtdPorPagina = this.qtdLinhas * this.qtdPorLinha
      if (this.qtdPorPagina < 9) {
        this.qtdPorPagina = 9
      }
      if (this.widthAtualizado < 440) {
        this.qtdPorPagina = 10
      }
      // this.$store.commit('cidade-proxima/putCampoForm', { campo: 'qtdPorPagina', value: this.qtdPorPagina })
      // fim copiar e colar lá
    },
    async buscarPossiveis () {
      this.elasticSearchModel = new ElasticSearchModel()
      this.carregandoBusca = true
      return this.elasticSearchModel.possiveis().then(retorno => {
        LocalStorage.set('retornoPossiveis', retorno)
      })
    },
    async buscar () {
      return this.elasticSearchModel
        .buscar([], this.$store, 1, this.qtdPorPagina)
        .then(retorno => {
          LocalStorage.set('retornoAnuncios', retorno)
        })
    }
  }
}
